import Vue from "vue";
import store from "../store";
import router from "@/router/index.js";
import { i18n } from "@/locales/i18n.js";

const auth = Vue.prototype.$firebase.auth();
export default {
  async getCountry() {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("auth/countryCode")
        .then((v) => {
          return resolve(v);
        })
        .catch((e) => {
          console.log(e);
          return reject(e);
        });
    });
  },

  /**
   * 사용자 폰번호를 이용한 회원가입 함수
   *
   * @param {Object} params - 회원가입에 필요한 파라미터
   * @param {string} params.email - 사용자의 이메일
   * @param {string} params.password - 사용자의 비밀번호
   * @param {string} params.phoneNumber - 사용자의 전화번호 (예: 010-3535-2657)
   * @param {string} params.countryCode - 국가 코드 (예: +82)
   * @param {string} params.name - 사용자의 이름
   * @param {Object} params.userInfo - 추가 사용자 정보
   * @param {string} params.rule - 사용자 규칙
   * @param {boolean} params.marketing - 마케팅 동의 여부
   *
   * @returns {Promise} - 회원가입 처리 결과
   */
  signUpWithPhone(params) {
    return new Promise((resolve, reject) => {
      // 전화번호를 국가 코드와 합쳐 인증용 전화번호 생성
      let authPhoneNumber = params.phoneNumber.startsWith("0")
        ? params.countryCode + params.phoneNumber.slice(1)
        : params.countryCode + params.phoneNumber;

      // 사용자 존재 여부 확인
      Vue.prototype.$axios
        .get("auth/check-user-existence", {
          params: {
            email: params.email,
            phone: authPhoneNumber,
          },
        })
        .then((response) => {
          // 사용자 존재 시 에러 처리
          if (response.status !== 204) {
            Vue.toasted.global.error(i18n.t("auth.same_account_exist"));
            return reject(new Error("User already exists"));
          }

          // 사용자 생성
          return auth.createUserWithEmailAndPassword(
            params.email,
            params.password
          );
        })
        .then((response) => {
          // 사용자 초기화 API 호출
          return Vue.prototype.$axios.patch("auth/InitUser", {
            uid: response.user.uid,
            email: params.email,
            countryCode: params.countryCode,
            phoneNumber: params.phoneNumber,
            authPhoneNumber: authPhoneNumber,
            name: params.name,
            password: params.password,
            userInfo: params.userInfo,
            rule: params.rule,
            marketing: params.marketing,
            photoURL: "",
          });
        })
        .then(async (response) => {
          // 로그인 완료 처리
          this.finishLogin();
          await store.dispatch("auth/user/SET_USER", auth.currentUser);
          resolve(response);
        })
        .catch((e) => {
          // 에러 처리
          console.error(e);
          Vue.toasted.global.error(i18n.t("auth.creating_user_failed"));
          reject(e);
        });
    });
  },
  /**
   * 비밀번호를 이용한 사용자 로그인 함수
   *
   * @param {string} phone - 사용자의 전화번호
   * @param {string} email - 사용자의 이메일
   * @param {string} password - 사용자의 비밀번호
   * @param {boolean} keepSignIn - 로그인 유지 여부
   *
   * @returns {Promise} - 로그인 처리 결과
   */
  signInWithPassword(phone, email, password, keepSignIn) {
    return new Promise((resolve, reject) => {
      // 이미 로그인된 경우 예외 처리
      if (auth.currentUser) {
        return reject(new Error("already signed in"));
      }

      // 사용자 존재 여부 확인
      Vue.prototype.$axios
        .get("auth/check-user-existence", {
          params: { phone, email },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.uid.includes("kakao")) {
              Vue.toasted.global.error(i18n.t("auth.kakao_account_exist"));
              return reject("Kakao account available");
            } else if (res.data.uid.includes("naver")) {
              Vue.toasted.global.error(i18n.t("auth.naver_account_exist"));
              return reject("Naver account available");
            }

            if (
              res.data.providerData?.length > 0 &&
              res.data.providerData[0].providerId === "google.com"
            ) {
              Vue.toasted.global.error(i18n.t("auth.google_account_exist"));
              return reject("Google account available");
            }
          } else if (res.status == 204) {
            Vue.toasted.global.error(i18n.t("auth.no_account_exist"));
            return reject("No account available");
          } else {
            Vue.toasted.global.error(i18n.t("auth.unknown_error_occured"));
            return reject("Unknown error");
          }

          // 로그인 지속성 설정
          return auth.setPersistence(
            keepSignIn
              ? Vue.prototype.$firebase.auth.Auth.Persistence.LOCAL
              : Vue.prototype.$firebase.auth.Auth.Persistence.SESSION
          );
        })
        .then(async () => {
          // 이메일이 없는 경우 전화번호로 사용자 정보 조회
          if (!email) {
            const response = await Vue.prototype.$axios
              .patch("auth/getUserWithPhone", { phone })
              .catch((e) => {
                console.error(e);
                Vue.toasted.global.error(i18n.t("auth.unknown_error_occured"));
                return reject(e);
              });

            email = response.data.email;
          }

          // 이메일과 비밀번호로 로그인
          await auth.signInWithEmailAndPassword(email, password).catch((e) => {
            console.error(e);
            Vue.toasted.global.error(i18n.t("auth.account_password_mismatch"));
            return reject(e);
          });

          // 로그인 상태 확인 및 사용자 정보 저장
          if (auth.currentUser) {
            await auth.currentUser.getIdToken();
            auth.onAuthStateChanged(async (user) => {
              if (user) {
                await store.dispatch("auth/user/SET_USER", auth.currentUser);
              }
            });
            await this.finishLogin();
            return resolve();
          }
        })
        .catch((e) => {
          console.error(e);
          Vue.toasted.global.error(i18n.t("auth.unknown_error_occured"));
          return reject(e);
        });
    });
  },
  /**
   * 구글 로그인 및 회원가입 처리 함수
   *
   * @param {boolean} keepSignIn - 로그인 유지 여부
   *
   * @returns {Promise} - 로그인 처리 결과
   */
  async signInWithGoogle(keepSignIn) {
    return new Promise((resolve, reject) => {
      const provider = new Vue.prototype.$firebase.auth.GoogleAuthProvider();
      auth.languageCode = "ko";

      // 1. setPersistence 세션 만료 조건 지정
      auth
        .setPersistence(
          keepSignIn
            ? Vue.prototype.$firebase.auth.Auth.Persistence.LOCAL
            : Vue.prototype.$firebase.auth.Auth.Persistence.SESSION
        )
        .then(async () => {
          try {
            // 2. 구글 로그인 팝업
            const result = await auth.signInWithPopup(provider);
            console.log(result);

            // 3. 현재 유저 전화번호 확인 및 처리
            if (!result.user.phoneNumber) {
              // 처음 로그인이거나, 회원가입을 하지 않은 경우
              router.push({
                name: "SignUp",
                query: { provider: "google" },
              });
              return resolve();
            } else {
              // 4. 현재 유저 토큰 가져오기 및 유저 상태 업데이트
              await store.dispatch("auth/user/SET_USER", auth.currentUser);
              this.finishLogin();
              return resolve();
            }
          } catch (e) {
            // 에러 처리
            switch (e.code) {
              case "auth/invalid-email":
                return reject(new Error("invalid-email"));
              case "auth/email-already-in-use":
                return reject(new Error("email-already-in-use"));
              case "auth/popup-closed-by-user":
                return reject(new Error("popup-closed-by-user"));
              case "auth/popup-blocked":
                return reject(new Error("popup-blocked"));
              default:
                console.error(e);
                return reject(new Error("unknown"));
            }
          }
        })
        .catch((e) => {
          console.error("Error in setting persistence:", e);
          return reject(e);
        });
    });
  },
  /**
   * 구글 회원가입 완료 처리 함수
   *
   * @param {Object} params - 회원가입에 필요한 파라미터
   *
   * @returns {Promise} - 회원가입 처리 결과
   */
  finishSignUpWithGoogle(params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .patch("auth/InitUser", params)
        .then(async (res) => {
          // 로그인 완료 처리 및 유저 상태 업데이트
          await this.finishLogin();
          await store.dispatch("auth/user/SET_USER", auth.currentUser);
          return resolve(res);
        })
        .catch((e) => {
          console.error("Error in finishing sign up with Google:", e);
          if (e.code === "auth/phone-number-already-exists") {
            Vue.toasted.global.error(i18n.t("auth.phone_account_exist"));
          } else {
            Vue.toasted.global.error(i18n.t("auth.creating_user_error"));
          }
          return reject(e);
        });
    });
  },
  /**
   * 카카오 로그인 페이지로 리디렉션하는 함수
   *
   * @param {boolean} keepSignIn - 로그인 유지 여부
   */
  redirectToKakao(keepSignIn) {
    const protocol = location.protocol;
    const hostName = location.hostname;
    const port = location.port;

    let url = `${protocol}//${hostName}${port ? `:${port}` : ""}`;
    url += "/callback/kakaotalk";

    window.Kakao.Auth.authorize({
      redirectUri: url,
      throughTalk: true,
      state: keepSignIn.toString(),
    });
  },
  /**
   * 카카오 로그인 처리 함수
   *
   * @param {string} kakaoAuthCode - 카카오 인증 코드
   * @param {boolean} keepSignIn - 로그인 유지 여부
   *
   * @returns {Promise} - 로그인 처리 결과
   */
  signInWithKakao(kakaoAuthCode, keepSignIn) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .post("auth/kakaoLogin", { code: kakaoAuthCode })
        .then(async (result) => {
          try {
            const kakaoAccount = result.data.kakao_account;
            const resultPhoneNumber = kakaoAccount?.phone_number;
            const authPhoneNumber = resultPhoneNumber?.replace(/-|\s/g, "");
            let phone = resultPhoneNumber?.replace(/(\+\d[1-3]\s)|-/g, "");
            phone = phone ? "0" + phone : "";
            const code = resultPhoneNumber
              ?.match(/\+\d[1-3]\s/)[0]
              ?.slice(0, -1);
            const email = kakaoAccount.email;

            // 사용자 존재 여부 확인
            const userRecord = await Vue.prototype.$axios.get(
              "auth/check-user-existence",
              {
                params: {
                  email,
                  phone: resultPhoneNumber ? authPhoneNumber : "",
                },
              }
            );

            if (userRecord.status !== 204) {
              const uid = userRecord.data.uid;

              if (uid.includes("kakao:")) {
                const token = await Vue.prototype.$axios.patch(
                  "auth/GetToken",
                  { uid, provider: "kakao" }
                );

                await auth.setPersistence(
                  keepSignIn === "true"
                    ? Vue.prototype.$firebase.auth.Auth.Persistence.LOCAL
                    : Vue.prototype.$firebase.auth.Auth.Persistence.SESSION
                );

                await auth.signInWithCustomToken(token.data);
                const res = await this.finishLogin();
                return resolve(res);
              } else {
                const providerData = userRecord.data.providerData;
                const providerId =
                  providerData?.length > 0 && providerData[0].providerId;

                if (providerId === "google.com") {
                  Vue.toasted.global.error(i18n.t("auth.google_account_exist"));
                } else if (uid.includes("naver")) {
                  Vue.toasted.global.error(i18n.t("auth.naver_account_exist"));
                } else {
                  Vue.toasted.global.error(i18n.t("auth.phone_account_exist"));
                }

                router.push({ name: "Login" });
                return reject(
                  new Error("Existing account with different provider")
                );
              }
            } else {
              await router.push({
                name: "SignUp",
                params: {
                  name: kakaoAccount.name,
                  email,
                  phone,
                  countryCode: code,
                  uid: `kakao:${result.data.id}`,
                  photoURL: result.data.properties.profile_image,
                },
                query: { provider: "kakao" },
              });
              return resolve(userRecord.data);
            }
          } catch (e) {
            console.error(e);
            Vue.toasted.global.error(i18n.t("auth.common_login_failed"));
            router.push({ name: "Login" });
            return reject(e);
          }
        })
        .catch((e) => {
          console.error(e);
          Vue.toasted.global.error(i18n.t("auth.common_login_failed"));
          router.push({ name: "Login" });
          return reject(e);
        });
    });
  },

  /**
   * 카카오 회원가입 완료 처리 함수
   *
   * @param {Object} params - 회원가입에 필요한 파라미터
   *
   * @returns {Promise} - 회원가입 처리 결과
   */
  signUpWithKakao(params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .patch("auth/CreateToken", {
          email: params.email,
          emailVerified: true,
          phoneNumber: params.authPhoneNumber,
          displayName: params.name,
          photoURL: params.photoURL,
          disabled: false,
          uid: params.uid,
          provider: "kakao",
        })
        .then(async (fbToken) => {
          try {
            await Vue.prototype.$firebase
              .auth()
              .setPersistence(
                Vue.prototype.$firebase.auth.Auth.Persistence.LOCAL
              );

            const tokenResult = await Vue.prototype.$firebase
              .auth()
              .signInWithCustomToken(fbToken.data);
            const user = tokenResult.user;

            await Vue.prototype.$axios.patch("auth/InitUser", params);

            await store.dispatch("auth/user/SET_USER", user);
            await this.finishLogin();
            return resolve(user);
          } catch (e) {
            console.error(e);
            Vue.toasted.global.error(i18n.t("auth.token_login_failed"));
            router.push({ name: "Login" });
            return reject(e);
          }
        })
        .catch((e) => {
          console.error(e);
          Vue.toasted.global.error(i18n.t("auth.token_recieve_failed"));
          router.push({ name: "Login" });
          return reject(e);
        });
    });
  },
  /**
   * 네이버 로그인 페이지로 리디렉션하는 함수
   *
   * @param {boolean} keepSignIn - 로그인 유지 여부
   */
  redirectToNaver(keepSignIn) {
    const state =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    window.localStorage.setItem("naverState", state);

    const protocol = location.protocol;
    const hostName = location.hostname;
    const port = location.port;

    let url = `${protocol}//${hostName}${port ? `:${port}` : ""}`;
    url += "/callback/naver";

    const authUrl = "https://nid.naver.com/oauth2.0/authorize";
    const params = [
      "response_type=code",
      `client_id=${process.env.VUE_APP_NAVER_APP_CLIENT_ID}`,
      `redirect_uri=${url}`,
      `state=${state}`,
      `keepSignIn=${keepSignIn}`,
    ];

    const authCodeUrl = `${authUrl}?${params.join("&")}`;
    location.href = authCodeUrl;
  },
  /**
   * 네이버 로그인 처리 함수
   *
   * @param {string} naverAuthCode - 네이버 인증 코드
   * @param {boolean} keepSignIn - 로그인 유지 여부
   *
   * @returns {Promise} - 로그인 처리 결과
   */
  signInWithNaver(naverAuthCode, keepSignIn) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .post("auth/naverLogin", { code: naverAuthCode })
        .then(async (result) => {
          try {
            const naverAccount = result.data;
            const authPhoneNumber = naverAccount?.mobile_e164;
            const phone = naverAccount?.mobile?.replace(/-/g, "");
            const code = authPhoneNumber?.replace(
              phone.startsWith("0") ? phone.slice(1) : phone,
              ""
            );
            const email = naverAccount.email;

            if (!phone) {
              Vue.toasted.global.error(
                "SNS 계정에 저장된 연락처가 없습니다. 다른 방법으로 진행해주세요."
              );
              router.push({ name: "Login" });
              return reject(new Error("Phone number is empty"));
            }

            // 사용자 존재 여부 확인
            const userRecord = await Vue.prototype.$axios.get(
              "auth/check-user-existence",
              {
                params: { phone: authPhoneNumber, email: email },
              }
            );

            if (userRecord.status !== 204) {
              const uid = userRecord.data.uid;

              if (uid.includes("naver:")) {
                const token = await Vue.prototype.$axios.patch(
                  "auth/GetToken",
                  { uid, provider: "naver" }
                );

                await auth.setPersistence(
                  keepSignIn === "true"
                    ? Vue.prototype.$firebase.auth.Auth.Persistence.LOCAL
                    : Vue.prototype.$firebase.auth.Auth.Persistence.SESSION
                );

                const tokenResult = await auth.signInWithCustomToken(
                  token.data
                );
                this.token = naverAccount.naver_token;
                window.localStorage.setItem(
                  "NaverToken",
                  naverAccount.naver_token
                );

                const user = tokenResult.user;
                await store.dispatch("auth/user/SET_USER", user);
                await this.finishLogin();
                return resolve(user);
              } else {
                const providerData = userRecord.data.providerData;
                const providerId =
                  providerData?.length > 0 && providerData[0].providerId;

                if (providerId === "google.com") {
                  Vue.toasted.global.error(i18n.t("auth.google_account_exist"));
                } else if (uid.includes("kakao")) {
                  Vue.toasted.global.error(i18n.t("auth.kakao_account_exist"));
                } else {
                  Vue.toasted.global.error(i18n.t("auth.phone_account_exist"));
                }

                router.push({ name: "Login" });
                return reject(
                  new Error("Existing account with different provider")
                );
              }
            } else {
              // 신규 계정 생성
              await router.push({
                name: "SignUp",
                params: {
                  name: naverAccount.name,
                  email: email,
                  phone: phone,
                  countryCode: code,
                  uid: `naver:${naverAccount.id}`,
                  photoURL: naverAccount.profile_image,
                },
                query: { provider: "naver" },
              });
              return resolve(userRecord.data);
            }
          } catch (e) {
            console.error(e);
            Vue.toasted.global.error(i18n.t("auth.common_login_failed"));
            router.push({ name: "Login" });
            return reject(e);
          }
        })
        .catch((e) => {
          console.error(e);
          Vue.toasted.global.error(i18n.t("auth.common_login_failed"));
          router.push({ name: "Login" });
          return reject(e);
        });
    });
  },

  /**
   * 네이버 회원가입 완료 처리 함수
   *
   * @param {Object} params - 회원가입에 필요한 파라미터
   *
   * @returns {Promise} - 회원가입 처리 결과
   */
  signUpWithNaver(params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .patch("auth/CreateToken", {
          email: params.email,
          emailVerified: true,
          phoneNumber: params.authPhoneNumber,
          displayName: params.name,
          photoURL: params.photoURL,
          disabled: false,
          uid: params.uid,
          provider: "naver",
        })
        .then(async (fbToken) => {
          try {
            await Vue.prototype.$firebase
              .auth()
              .setPersistence(
                Vue.prototype.$firebase.auth.Auth.Persistence.LOCAL
              );

            const tokenResult = await Vue.prototype.$firebase
              .auth()
              .signInWithCustomToken(fbToken.data);
            const user = tokenResult.user;

            await Vue.prototype.$axios.patch("auth/InitUser", params);

            await store.dispatch("auth/user/SET_USER", user);
            await this.finishLogin();
            return resolve(user);
          } catch (e) {
            console.error(e);
            Vue.toasted.global.error(i18n.t("auth.token_login_failed"));
            router.push({ name: "Login" });
            return reject(e);
          }
        })
        .catch((e) => {
          console.error(e);
          Vue.toasted.global.error(i18n.t("auth.token_recieve_failed"));
          router.push({ name: "Login" });
          return reject(e);
        });
    });
  },

  /*
   * 채널톡 Plugin Reboot
   */
  rebootChannelIo() {
    if (auth.currentUser) {
      window.ChannelIO(
        "boot",
        {
          pluginKey: "e6541c37-05a3-4119-8e7f-88b9c21a509c",
          memberId: auth.currentUser.uid,
          profile: {
            name: auth.currentUser.displayName,
            mobileNumber: auth.currentUser.phoneNumber,
          },
        },
        function onBoot(error, user) {
          if (error) {
            console.error(error);
          } else {
            window.channelUser = user;
          }
        }
      );
    } else {
      window.ChannelIO("shutdown");
    }
  },

  getStoreUserData() {
    return new Promise((resolve, reject) => {
      this.waitForCurrentUser()
        .then((user) => {
          Vue.prototype.$axios
            .get(`users/${user.uid}`)
            .then(async (res) => {
              // 데이터 변환 로직
              if (res.data.createdAt._seconds) {
                res.data.createdAt = new Date(
                  res.data.createdAt._seconds * 1000
                );
              }
              if (res.data.updatedAt._seconds) {
                res.data.updatedAt = new Date(
                  res.data.updatedAt._seconds * 1000
                );
              }
              if (res.data.visitedAt._seconds) {
                res.data.visitedAt = new Date(
                  res.data.visitedAt._seconds * 1000
                );
              }
              // claim을 통한 추가 검증
              const { claims } = await user.getIdTokenResult(true);
              res.data.level = claims.level;
              resolve(res.data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  waitForCurrentUser(timeout = 5000) {
    return new Promise((resolve, reject) => {
      const intervalTime = 100;
      let elapsed = 0;

      const check = setInterval(() => {
        if (auth.currentUser) {
          clearInterval(check);
          resolve(auth.currentUser);
        } else if (elapsed > timeout) {
          clearInterval(check);
          reject(new Error("Timeout waiting for auth.currentUser"));
        }
        elapsed += intervalTime;
      }, intervalTime);
    });
  },

  async finishLogin() {
    await auth.currentUser.reload();
    this.rebootChannelIo();
    return router
      .push({ name: "Home", query: { confirm: true } })
      .then(() => window.scrollTo(0, 0));
  },

  resetPasswordByPhone(phone) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .patch("auth/getUserWithPhone", { phone: phone })
        .then((res) => {
          //uid: kakao나 naver일경우
          //또는 provider가 google일경우
          if (
            res.data.uid.includes("kakao") ||
            res.data.uid.includes("naver") ||
            res.data.providerData[0].providerId == "google.com"
          ) {
            Vue.toasted.global.error(i18n.t("resetPassword.sns_account"));
            return reject();
          }

          this.resetPasswordByEmail(res.data.email)
            .then(() => {
              return resolve();
            })
            .catch((e) => {
              return reject(e);
            });
        })
        .catch((e) => {
          Vue.toasted.global.error(i18n.t("resetPassword.no_account_exist"));
          return reject(e);
        });
    });
  },

  resetPasswordByEmail(email) {
    return new Promise((resolve, reject) => {
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          Vue.toasted.global.notice(i18n.t("resetPassword.email_sended"));
          return resolve();
        })
        .catch((e) => {
          switch (e.code) {
            case "auth/invalid-email":
              Vue.toasted.global.error(i18n.t("resetPassword.invalid_email"));
              break;
            case "auth/email-already-in-use":
              Vue.toasted.global.error(
                i18n.t("resetPassword.email_already_in_use")
              );
              break;
            case "auth/user-not-found":
              Vue.toasted.global.error(i18n.t("resetPassword.user_not_found"));
              break;
            case "auth/missing-email":
              Vue.toasted.global.error(i18n.t("resetPassword.missing_email"));
              break;
            default:
              Vue.toasted.global.error(i18n.t("resetPassword.unknwon_error"));
              break;
          }
          return reject(e);
        });
    });
  },
};
